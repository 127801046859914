export const defaultCarleadCreationConfig = {
  locale: 'nl',
  emailSuggestionEnabled: true,
  bookingUrl: '/appointment/9-0',
  confirmationUrl: '/appointment/9-0/confirmed',
  redirectOnSuccessUrl: '/booking/{hash}/',
  featuresFlags: {
    redirectToSelfEvaluation: true,
    selfEvaHideOnlinePriceBucket: true,
    pricingScope: 'license-plate-rounded',
    exactMileage: true,
  },
  licensePlateFallbackPath: '/waarde/17-1/',
  invalidLicensePlateFallbackPath: '/waarde/17-1/',
  stepsTimeTracking: {
    isEnabledFor: ['StepCompound'], options: {
      idleTimeout: 20, afterIDLETimeoutInMilliseconds: 10 * 1000,
    },
  },
  marketingAttributes: {
    "lead-mileagetextfield-112022-v1": 1, "repositioning-202209-v2": 1,
  },
  selfEvaNoBookingPageBucket: 1,
  selfEvaHideOnlinePriceBucket: 1,
  roundedPrice: true,
  isMileageInputEnabled: true,
  unspecifiedMarketingExperiment: true,
  trackLandingPageType: 'lead',
  syncQuestionnaireFieldsToCookie: true,
  returningCustomerPopup: [
    'lead'
  ]
};
